import React from 'react'
import styled from 'styled-components'
import Wizard from '@ubo/thuisaanbouw'

const StyledWizard = styled.div`
  height: calc(100vh - 100px);

  @media (min-width: 1599px){
    padding: 150px 0 !important;
  }

  @media (min-width: 992px) {
    padding: 100px 0 50px;
  }

  @media (max-width: 991px) {
    padding: 100px 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  @media (max-width: 575.98px) {
    padding: 20px 0;
  }
`

export default function Configurator() {
  const isSSR = typeof window === 'undefined'

  return (
    <StyledWizard className="w-100 bg-grey">
      <div className="d-flex align-items-center justify-content-center h-100">
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <Wizard />
          </React.Suspense>
        )}
      </div>
    </StyledWizard>
  )
}
