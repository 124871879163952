import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

interface ComponentsShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
  location?: any
}

interface ComponentProps {
  [key: string]: any
}

const ComponentsShell: React.FC<ComponentsShellProps> = ({
  fields,
  location = {},
}) => {
  const types: ComponentProps = {
    usp_horizontal: loadable(
      () => import('components/flex/Components/UspHorizontal')
    ),
    usp_vertical: loadable(
      () => import('components/flex/Components/UspVertical')
    ),
    workmethod: loadable(() => import('components/flex/Components/Workmethod')),
    workmethod_steps: loadable(
      () => import('components/flex/Components/WorkmethodSteps')
    ),
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default ComponentsShell
