import React from 'react'

// Third Party
import styled from 'styled-components'

interface WhitespaceProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Whitespace
}

const StyledWhitespace = styled.section<{
  heightdesktop: number
  heightlaptop: number
  heighttablet: number
  heightmobile: number
}>`
  height: ${(props) => props.heightmobile}px;

  @media (min-width: 576px) {
    height: ${(props) => props.heighttablet}px;
  }

  @media (min-width: 992px) {
    height: ${(props) => props.heightlaptop}px;
  }

  @media (min-width: 1200px) {
    height: ${(props) => props.heightdesktop}px;
  }
`

const Whitespace: React.FC<WhitespaceProps> = ({ fields }) => (
  <StyledWhitespace
    heightdesktop={fields.heightdesktop || 0}
    heightlaptop={fields.heightlaptop || 0}
    heighttablet={fields.heighttablet || 0}
    heightmobile={fields.heightmobile || 0}
  />
)

export default Whitespace
