/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export const encodeQueryData = (data: Array<string>) => {
  const ret = []
  for (const d in data)
    ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`)
  return ret.join('&')
}

export const chunkArray = (array: Array<any>, size: number) => {
  const chunkedArray = []
  const copied = [...array] // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / size) // Round up to the nearest integer

  for (let i = 0; i < numOfChild; i += 1) {
    chunkedArray.push(copied.splice(0, size))
  }

  return chunkedArray
}

export const calculateColumn = (length: number) => 12 / length
