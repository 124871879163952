import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Images
import LogoFooter from 'img/logo_footer.inline.svg'
import PurplePin from 'img/purple_pin.inline.svg'
import PurpleMail from 'img/purple_mail.inline.svg'
import PurplePhone from 'img/purple_phone.inline.svg'

// Util
import { chunkArray } from 'utils'

// Third Party
import useMedia from 'use-media'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.tertiary};
  color: ${(props) => props.theme.color.light};

  & ul {
    padding: 0;

    & li {
      list-style: none;
      line-height: 35px;

      & a {
        color: ${({ theme }) => theme.color.light};
        font-weight: ${({ theme }) => theme.font.weight.light} !important;
        font-size: 16px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 991px) {
    padding-top: 30px;
  }

  @media (max-width: 767px) {
    & ul {
      & li {
        line-height: 25px;
      }
    }
  }
`

const Title = styled.h3`
  font-size: 14px;
`

const Usps = styled.div`
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.light} !important;
  font-size: 35px;
  line-height: 40px;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`

const Dot = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 20px;

  @media (max-width: 767px) {
    margin: 0 10px;
  }
`

const SmallDot = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  height: 3px;
  width: 3px;
  border-radius: 100%;
  margin: 0 5px 0 0;
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  height: 1px;
  width: 100%;
`

const SvgWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: flex-start;
`

const ContactInfo = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.light} !important;

  & a {
    color: ${({ theme }) => theme.color.light} !important;

    &:hover {
      text-decoration: underline;
    }
  }
`

const FooterBottom = styled.div`
  background-color: ${({ theme }) => theme.color.primary};

  & a,
  & button {
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.small};

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            link {
              title
              url
            }
          }
          contactFooter {
            address
            mail {
              title
              url
            }
            phone {
              title
              url
            }
          }
          usps {
            usp
          }
          bottom {
            privacyStatement {
              url
              title
            }
            termsAndConditions {
              url
              title
            }
          }
        }
      }
    }
  `)

  let array = fields?.footer?.menuFooter || []

  const isMobile = useMedia({ maxWidth: 768 })
  // @ts-ignore
  let chunkedArray = chunkArray(array, 3)

  if (isMobile) {
    // @ts-ignore
    chunkedArray = chunkArray(array, 5)
  }

  return (
    <StyledFooter>
      <div className="container py-sm-5 py-3">
        <div className="row gy-sm-5 gy-4">
          <div className="col-lg-6 d-flex align-items-center">
            <LogoFooter />
          </div>
          <div className="col-lg-6">
            <Usps className="d-flex align-items-center">
              {fields?.footer?.usps?.map((usp, index) => (
                <React.Fragment key={index}>
                  <div key={index}>{usp?.usp}</div>
                  {(fields?.footer?.usps?.length || 3) - 1 !== index && <Dot />}
                </React.Fragment>
              ))}
            </Usps>
          </div>
          <div className="col-lg-6 pt-lg-4">
            <Title>Contact</Title>
            <div style={{ maxWidth: 335 }}>
              <Line />
              <div className="pt-2">
                <div
                  style={{ lineHeight: '35px' }}
                  className="d-flex align-items-center"
                >
                  <SvgWrapper>
                    <PurplePin />
                  </SvgWrapper>
                  <ContactInfo className="ms-2">
                    {fields?.footer?.contactFooter?.address}
                  </ContactInfo>
                </div>
                <div
                  style={{ lineHeight: '35px' }}
                  className="d-flex align-items-center"
                >
                  <SvgWrapper>
                    <PurpleMail />
                  </SvgWrapper>
                  <ContactInfo className="ms-2">
                    <a href={fields?.footer?.contactFooter?.mail?.url || '/'}>
                      {fields?.footer?.contactFooter?.mail?.title}
                    </a>
                  </ContactInfo>
                </div>
                <div
                  style={{ lineHeight: '35px' }}
                  className="d-flex align-items-center"
                >
                  <SvgWrapper>
                    <PurplePhone />
                  </SvgWrapper>
                  <ContactInfo className="ms-2">
                    <a href={fields?.footer?.contactFooter?.phone?.url || '/'}>
                      {fields?.footer?.contactFooter?.phone?.title}
                    </a>
                  </ContactInfo>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 pt-lg-4">
            <Title>Menu</Title>
            <div style={{ maxWidth: 520 }}>
              <Line />
              <div className="d-flex align-items-start justify-content-between pt-2">
                {chunkedArray.map((chunk, index) => (
                  <ul className="px-sm-3 px-1" key={`ul-${index}`}>
                    {chunk.map(
                      ({ link: { url, title } }: any, subIndex: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li
                          className="d-flex align-items-center"
                          key={`li-${subIndex}`}
                        >
                          <SmallDot />
                          <Link to={url}>{title}</Link>
                        </li>
                      )
                    )}
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom>
        <div className="mt-4 py-3 container d-flex align-items-center justify-content-center">
          <a href={fields?.footer?.bottom?.termsAndConditions?.url || ''}>
            {fields?.footer?.bottom?.termsAndConditions?.title}
          </a>
          <div className="mx-2">-</div>
          <a href={fields?.footer?.bottom?.privacyStatement?.url || ''}>
            {fields?.footer?.bottom?.privacyStatement?.title}
          </a>
          <div className="mx-2">-</div>
          <button
            type="button"
            onClick={() => {
              // @ts-ignore
              if (typeof Cookiebot !== 'undefined') Cookiebot.show()
            }}
          >
            Cookies
          </button>
        </div>
      </FooterBottom>
    </StyledFooter>
  )
}

export default Footer
