import React from 'react'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import Menu from 'components/layout/Header/Menu'
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { StyledButtonSecondary } from './Desktop'

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  & ul {
    padding-left: 0;
    margin: 0;
    width: 70%;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-right: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.primary};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 4px;
    border-radius: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  height: 4px;
  width: 50%;
  margin: 5px auto;
  border-radius: 2px;
`

const Brand = styled(NavLink)`
  background-color: ${({ theme }) => theme.color.tertiary};
  padding: 21px 30px 30px 30px;
  border-radius: 0 0 18px 18px;
  justify-self: flex-start;
  position: absolute;
  top: 0;
  left: 12px;

  & > svg {
    height: 64px;
    width: 182px;
  }

  @media (max-width: 575px) {
    padding: 10px 15px 10px 15px;

    & > svg {
      width: 130px;
    }
  }
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <Brand to="/">
            <Logo />
          </Brand>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
            <li style={{ listStyleType: 'none' }}>
              {/* @ts-ignore */}
              <StyledButtonSecondary
                className="mt-4"
                to="/ontwerp-je-eigen-aanbouw/"
              >
                Stel jouw aanbouw samen
              </StyledButtonSecondary>
            </li>
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

export default Mobile
