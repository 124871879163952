/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'

// Services
import convertStyle from 'services/convertStyle'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

const Content = styled.div`
  font-family: ${({ theme }) => theme.font.family.primary};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.regular};
    line-height: 36px;

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    & li {
    }
  }

  & p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.secondary};
    text-decoration: underline;
  }

  & img {
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: ${({ theme }) => theme.font.size.tiny};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }
`

const findAndReplace = (content: string) =>
  parse(content, {
    replace: (domNode) => {
      const { name, attribs, children }: any = domNode

      if (name === 'a' && attribs.class === 'button-primary') {
        return (
          <ButtonPrimary to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonPrimary>
        )
      }

      if (name === 'a' && attribs.class === 'button-secondary') {
        return (
          <ButtonSecondary to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonSecondary>
        )
      }

      if (name === 'a') {
        const attributes = attribs

        if (attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1 ||
          attributes.href.indexOf('tel:') !== -1 ||
          attributes.href.indexOf('mailto:') !== -1
        ) {
          return <a {...attributes}>{domToReact(children, {})}</a>
        }

        return (
          <Link to={attributes.href} {...attributes}>
            {domToReact(children, {})}
          </Link>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: string
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  if (!content) {
    return null
  }

  return <Content className={className}>{findAndReplace(content)}</Content>
}

export default ParseContent
