import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

const StyledDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
  padding-left: 15px;
`

export const StyledButtonSecondary = styled(ButtonSecondary)`
  @media (max-width: 1199px) {
    min-width: unset;

    & a {
      font-size: 14px;
      padding: 8px 10px;
    }
  }

  @media (max-width: 991px) {
    & a {
      font-size: ${({ theme }) => theme.font.size.large};
      padding: 15px 30px;
    }
  }
`

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
}

const Desktop: React.FC<DesktopProps> = ({ fields }) => (
  <StyledDesktop className="d-none d-lg-flex">
    <div className="d-flex w-100 justify-content-evenly">
      <Menu fields={fields} />
    </div>
    {/* @ts-ignore */}
    <StyledButtonSecondary to="/ontwerp-je-eigen-aanbouw/">
      Stel jouw aanbouw samen
    </StyledButtonSecondary>
  </StyledDesktop>
)

export default Desktop
