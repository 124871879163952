const mainTheme = {
  color: {
    primary: '#4F48ED',
    secondary: '#FF7629',
    tertiary: '#1C1A33',
    grey: '#F5F5F5',
    dark: '#000000',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'apertura, sans-serif',
      secondary: 'apertura, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 700,
      extraBold: 800,
    },
  },
}

export default mainTheme
