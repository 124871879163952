import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

// Icon
import favicon from 'img/favicon.png'

export interface SeoProps {
  opengraphImage: {
    sourceUrl: string
  }
  title: string
  metaDesc: string
  canonical: string
  opengraphTitle: string
  opengraphUrl: string
}

const removeHTML = (content: string) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

const SEO: React.FC<{ seo: SeoProps }> = ({ seo }) => {
  const {
    site,
    wpComponent,
    // @ts-ignore
  } = useStaticQuery<any>(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      wpComponent(databaseId: { eq: 204 }) {
        socialSharing {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const defaultImageUrl = wpComponent?.socialSharing?.image
    ? `${site?.siteMetadata?.siteUrl}${wpComponent?.socialSharing?.image?.localFile?.publicURL}`
    : ''

  const image: string = seo.opengraphImage
    ? seo.opengraphImage.sourceUrl
    : defaultImageUrl

  return (
    // @ts-ignore
    <Helmet
      title={removeHTML(seo.title)}
      htmlAttributes={{
        lang: 'nl',
      }}
    >
      {/* Default */}
      {seo.metaDesc && (
        <meta name="description" content={removeHTML(seo.metaDesc)} />
      )}
      {seo.canonical && (
        <link rel="canonical" href={removeHTML(seo.canonical)} />
      )}
      <meta name="image" content={image} />
      <link rel="icon" href={favicon} />

      {/* Language */}
      <meta httpEquiv="content-language" content="nl-nl" />
      <link
        rel="alternate"
        href={site?.siteMetadata?.siteUrl || ''}
        hrefLang="nl-nl"
      />

      {/* SEO */}
      {seo.opengraphTitle && (
        <meta property="og:type" content={seo.opengraphTitle} />
      )}
      {seo.opengraphUrl && (
        <meta property="og:url" content={seo.opengraphUrl} />
      )}
      {seo.title && (
        <meta property="og:title" content={removeHTML(seo.title)} />
      )}
      {seo.metaDesc && (
        <meta property="og:description" content={removeHTML(seo.metaDesc)} />
      )}
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="nl_NL" />
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="58d497eb-c776-4424-b36d-68f59a7492b2"
        type="text/javascript"
        async
      ></script>
    </Helmet>
  )
}

export default SEO
