/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import PurpleMail from 'img/purple_mail.inline.svg'
import PurplePhone from 'img/purple_phone.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Third Party
import styled, { css } from 'styled-components'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 75px;
  z-index: 10;
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const GreyBar = styled.div<{ isSticky: boolean }>`
  background-color: ${({ theme }) => theme.color.grey};
  height: 27px;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.isSticky &&
    css`
      height: 0;
    `}

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Brand = styled(NavLink)`
  background-color: ${({ theme }) => theme.color.tertiary};
  padding: 60px 30px 30px 30px;
  border-radius: 0 0 18px 18px;
  justify-self: flex-start;
  margin-left: -50px;

  @media (min-width: 1200) {
    & > svg {
      height: 64px;
      width: 182px;
    }
  }

  @media (max-width: 1199px) {
    margin-left: -30px;

    & > svg {
      height: 47px;
      width: 122px;
    }
  }

  @media (max-width: 991px) {
    margin-left: 0;
  }

  @media (max-width: 575px) {
    padding: 15px 15px 10px 15px;
    margin-top: -20px;

    & > svg {
      width: 100px;
      height: 30px;
    }
  }
`

const SvgWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: flex-start;
`

const ContactInfo = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.light} !important;

  & a {
    color: ${({ theme }) => theme.color.primary} !important;

    &:hover {
      text-decoration: underline;
    }
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
  customHeader?: any
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
  customHeader
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  return (
    <StyledHeader isSticky={isSticky}>
      <GreyBar isSticky={isSticky} />
      <Container className="container">
        <Brand to="/">
          <Logo />
        </Brand>

        {!customHeader && (
          <>
            <Mobile
              fields={fields}
              setIsScrollable={setIsScrollable}
              isSticky={isSticky}
            />
            <Desktop fields={fields} isSticky={isSticky} />
          </>
        )}

        {customHeader && (
          <div className="d-none d-lg-flex flex-wrap align-items-center justify-content-end justify-content-lg-start">
            <div
              style={{ lineHeight: '35px' }}
              className="d-flex align-items-center"
            >
              <SvgWrapper>
                <PurpleMail />
              </SvgWrapper>
              <ContactInfo className="ms-2">
                <a href={`mailto:info@thuisinaanbouw.nl` || '/'}>
                  info@thuisinaanbouw.nl
                </a>
              </ContactInfo>
            </div>
            <div
              style={{ lineHeight: '35px' }}
              className="d-flex align-items-center ms-3"
            >
              <SvgWrapper>
                <PurplePhone />
              </SvgWrapper>
              <ContactInfo className="ms-2">
                <a href={'tel:0858085384' || '/'}>
                  085 – 808 5384
                </a>
              </ContactInfo>
            </div>
          </div>
        )}
      </Container>
    </StyledHeader>
  )
}

export default Header
