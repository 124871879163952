import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

interface PostsShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

interface PostProps {
  [key: string]: any
}

const PostsShell: React.FC<PostsShellProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    blog: loadable(() => import('components/flex/Posts/Blog')),
    highlighted: loadable(() => import('components/flex/Posts/Highlighted')),
    highlighted_projects: loadable(
      () => import('components/flex/Posts/HighlightedProjects')
    ),
    highlighted_questions: loadable(
      () => import('components/flex/Posts/HighlightedQuestions')
    ),
    all_projects: loadable(() => import('components/flex/Posts/Projects')),
    all_questions: loadable(() => import('components/flex/Posts/Questions')),
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default PostsShell
